@font-face {
  font-family: 'NaturaSansRegular';
  src: url("../../src/fonts/NaturaSans-Regular_0.otf"); }

@font-face {
  font-family: 'NaturaSansRegularBold';
  src: url("../../src/fonts/NaturaSans-Bold_0.otf"); }

@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url("../../src/fonts/MaterialIcons-Regular.eot"); /* For IE6-8 */
  src: local('Material Icons'),
       local('MaterialIcons-Regular'),
       url("../../src/fonts/MaterialIcons-Regular.woff2") format('woff2'),
       url("../../src/fonts/MaterialIcons-Regular.woff") format('woff'),
       url("../../src/fonts/MaterialIcons-Regular.ttf") format('truetype');
}

*:focus {
  outline: none; }

*::-webkit-scrollbar-track {
  background-color: #F4F4F4; }

*::-webkit-scrollbar {
  width: 6px;
  background: #F4F4F4; }

*::-webkit-scrollbar-thumb {
  background: #dad7d7; }

* {
  font-family: "NaturaSansRegular", Helvetica, Arial, sans-serif; }

/* TODO - APPLOADER */
html {
  font-size: 100%;
}

html input::-ms-clear {
  display: none; }

body {
  background: #eee; }

.all-container {
  margin: 3em 5% 3em 5%; }

.paper-header {
  margin-left: .55em;
  color: #7181a0;
  letter-spacing: 1px; }

.text-bold {
  font-family: "NaturaSansRegularBold", Helvetica, Arial, sans-serif !important;
  font-weight: 700;
  margin-right: 0.5em; }

.av-block-ui-message svg circle {
  stroke: #7080a0 !important; }

.groups-container-login-background {
  background: #000000;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -1; }

.groups-container-login-background-img {
  background: rgba(0, 0, 0, 0.25); }

.groups-container-login-background-img img {
  width: 8em;
  margin-left: 3%;
  margin-top: 1em;
  padding-bottom: 1em;
  transition: all .3s; }

.groups-container-login-user-undefined {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 100px; }

.groups-container-login-user-undefined div {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 19px;
  color: #ff6b0b;
  height: 70px;
  background: rgba(242, 170, 51, 0.15);
  width: 40%;
  border: 2px dashed #ff6b0b;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px; }

.groups-container-login {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 3em;
  transition: all .2s; }

.groups-container-login-box {
  -webkit-transition: all .3s;
  -moz-transition: all .3s;
  -ms-transition: all .3s;
  -o-transition: all .3s;
  transition: all .3s;
  background: #fff;
  padding: 20px 50px 20px 50px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  box-shadow: 0px 0px 20px 0px #bbbbbb;
  width: 450px; }

.groups-container-login-box h3 {
  font-weight: 700;
  text-align: center;
  font-family: 'NaturaSansRegular', Helvetica, Arial, sans-serif;
  margin-bottom: 30px; }

.groups-body-login-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start; }

.groups-body-login-box span {
  font-family: 'NaturaSansRegular', Helvetica, Arial, sans-serif; }

.groups-body-login-box-btn {
  align-self: flex-end; }

.groups-body-login-box-user {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  flex-direction: row; }

.groups-body-login-box-pass {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  flex-direction: row; }

.groups-body-login-box-user svg {
  margin-bottom: 10px;
  margin-right: 10px; }

.groups-body-login-box-pass svg {
  margin-bottom: 10px;
  margin-right: 10px; }

.groups-body-login-box-error {
  text-align: center;
  color: #fd1100; }

@media screen and (max-width: 840px) {
  .groups-container-login {
    transition: all .2s;
    margin-top: 3em; } }

@media all and (max-width: 460px) {
  .groups-container-login-box {
    -webkit-transition: all .3s;
    -moz-transition: all .3s;
    -ms-transition: all .3s;
    -o-transition: all .3s;
    transition: all .3s;
    background: #fff;
    padding: 10px;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    width: 100%; }
  .groups-body-login-box {
    align-items: center; }
  .groups-container-login-background-img img {
    width: 8em;
    margin-left: 30% !important;
    margin-top: 1em;
    padding-bottom: 1em;
    transition: all .3s; } }

.appBar {
  justify-content: space-around;
  align-items: center;
  height: 6.8em;
  background: #7080a0 !important;
  padding: 0 2.5% !important; }
  .appBar div {
    margin-top: 0 !important; }

.appBar-icon-menu {
  padding: 0 !important; }
  .appBar-icon-menu svg {
    height: 60px !important;
    width: 60px !important; }

.appBar-title {
  display: flex;
  justify-content: center;
  align-items: center; }
  .appBar-title h2 {
    margin-left: 1em;
    padding-left: 1em;
    border-left: 1px solid #ffffff;
    font-weight: 600;
    margin-top: 0;
    margin-bottom: 0;
    height: 2em;
    display: flex;
    align-items: center;
    letter-spacing: 0.8px; }

.header-logo-img {
  width: 6em;
  padding-bottom: 8px; }

.appBar-info-user-container span {
  color: #ffffff;
  letter-spacing: 1px; }

.appBar-info-user-custom {
  font-weight: 200;
  align-self: baseline;
  margin-bottom: 0.7em !important; }
  .appBar-info-user-custom span:nth-child(1) {
    font-weight: 800 !important; }

.appBar-info-user-body {
  display: flex;
  justify-content: flex-end;
  padding-top: 1em; }
  .appBar-info-user-body svg {
    margin-left: .6em;
    cursor: pointer; }
  .appBar-info-user-body a:link {
    text-decoration: none;
    cursor: pointer;
    display: flex; }

.navigation-header {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 95px;
  background: #7080a0; }
  .navigation-header img {
    width: 10em; }

.navigation-title {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #7b7b7b;
  border-bottom: 1px solid #bbbbbb;
  margin-bottom: 1em;
  padding-bottom: 1em;
  margin-left: 1em;
  margin-right: 1em; }

.natigation-header-links {
  font-size: 20px !important;
  margin-bottom: 1em !important; }

.pagebase-title-homepage {
  font-size: 16px !important;
  font-family: "NaturaSansRegular", Helvetica, Arial, sans-serif;
  color: #777777; }

.homepage-content {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-around;
  align-items: center; }
  .homepage-content div:nth-child(4) {
    align-self: flex-end; }

.homepage-textfield input {
  font-size: 16px !important;
  font-family: "NaturaSansRegular", Helvetica, Arial, sans-serif;
  color: #777777 !important;
  font-family: "NaturaSansRegular", Helvetica, Arial, sans-serif !important; }

.homepage-textfield label {
  color: rgba(0, 0, 0, 0.3) !important; }

.homepage-textfield hr {
  border-bottom: 1px solid rgba(0, 0, 0, 0.3) !important; }

.homepage-messenger-container {
  background-color: #f1f1f1;
  height: 7em;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #777777;
  margin-right: 15%;
  margin-left: 15%;
  margin-top: 4em; }

.homepage-table-container {
  margin-top: 4em; }

.homepage-table-body-container-class tr:hover {
  background-color: #f9f9f9 !important;
  cursor: pointer; }

.homepage-table-container-class {
  margin-top: 1em; }
  .homepage-table-container-class .table {
    margin-bottom: 0; }
  .homepage-table-container-class .table-bordered {
    border: 0; }
  .homepage-table-container-class th {
    text-align: center !important;
    border-bottom-width: 0 !important;
    border: 0 !important;
    font-size: 16px; }
  .homepage-table-container-class tr {
    background-color: #ffffff !important;
    border-bottom: .5px solid #efefef !important; }
    .homepage-table-container-class tr td {
      text-align: center !important;
      border-bottom-width: 0 !important;
      border: 0 !important;
      color: rgba(0, 0, 0, 0.5); }
  .homepage-table-container-class .homepage-table-header-container-class {
    border-bottom: .5px solid #c7c7c7 !important;
    color: #777777; }
.react-bs-table-pagination{
  display: flex; }
.react-bs-table-pagination .row {
  display: flex;
  width: 100%;
  color: #777777;
  margin-top: 0 !important; }
  .react-bs-table-pagination .row > div {
    display: flex;
    justify-content: center;
    align-items: center; }
    .react-bs-table-pagination .row > div:nth-child(1) {
      width: 10%;
      justify-content: flex-start;}
      .react-bs-table-pagination .row > div:nth-child(1) button {
        color: #777777; }
    .react-bs-table-pagination .row > div:nth-child(2) {
      display: flex !important;
      align-items: center;
      justify-content: center;
      width: 90%; }
  .react-bs-table-pagination .row .active > a {
    background-color: #849dad;
    color: #ffffff;
    border-color: #849dad; }
  .react-bs-table-pagination .row .page-link {
    color: #777777; }

.homepage-messenge-busca {
  text-align: center; }

.report-page-base-button-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 3em; }

.report-page-base-paper-group-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 1em; }

.report-page-base-paper-container {
  height: 22em;
  padding: 1em; }

.paper-name-custom {
  width: 65%;
  margin-right: .7em; }

.paper-structure-custom {
  width: 35%; }

.report-page-base-paper-body {
  color: #777777;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 1em 1em 0 2em;
  line-height: 2.5; }

.report-page-base-paper-footer {
  color: #777777;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0 2em 1em;
  line-height: 2.5; }

.report-page-base-paper-body-text-gray {
  color: #777777; }

.report-page-base-paper-body-padding {
  width: 50%; }

.report-page-base-paper-body-custom {
  padding-right: 4em;
  width: 45%; }

.report-page-base-paper-body-papeis-content {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start; }

.report-page-base-paper-custom {
  display: flex;
  flex-direction: column;
  justify-content: flex-start; }

.report-page-base-papers {
  display: flex; }

.commercial-structure-paper-title {
  letter-spacing: 0;
  padding-top: 1em;
  font-size: 16px; }

.commercial-structure-paper-body-container {
  padding: 1.5em;
  position: relative; }

.commercial-structure-paper-body {
  color: #777777;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 1em; }

.commercial-structure-paper-body-line {
  height: 10em;
  width: 1px;
  border: 1px solid  #849dad;
  position: absolute;
  top: 26px;
  left: 27px; }

.commercial-structure-paper-body-ball {
  width: 14px;
  height: 14px;
  background: #849dad;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  -ms-border-radius: 50px;
  -o-border-radius: 50px;
  margin-right: 1.5em; }

.selectfield-periodo-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end; }
  .selectfield-periodo-container .selectfield-periodo {
    margin: 0 1.5em 0.5em 1.5em; }

.iconbutton-periodo {
  padding: 0 !important;
  width: 60px !important;
  height: 60px !important; }
  .iconbutton-periodo svg {
    width: 50px !important;
    height: 50px !important;
    color: #8498b2 !important; }

.growth-plan-report-title {
  color: #7181a0 !important;
  margin-bottom: 1em;
  margin-top: 1em;
  margin-left: .55em;
  font-family: "NaturaSansRegularBold", Helvetica, Arial, sans-serif !important;
  font-weight: 500; }
  .growth-plan-report-title span {
    font-family: "NaturaSansRegularBold", Helvetica, Arial, sans-serif !important; }

.growth-plan-report-title-custom {
  color: #7181a0;
  margin-bottom: 1em;
  margin-top: 2em;
  margin-left: 0;
  font-weight: 500;
  font-family: "NaturaSansRegularBold", Helvetica, Arial, sans-serif !important; }
  .growth-plan-report-title-custom span {
    font-family: "NaturaSansRegularBold", Helvetica, Arial, sans-serif !important; }

.growth-plan-report-container {
  margin-top: 1em;
  margin-left: 1em;
  margin-bottom: 3em; }
  .growth-plan-report-container h4 {
    color: #777777 !important; }

.growth-plan-report-table-container {
  width: 100%;
  margin-top: 3em; }
  .growth-plan-report-table-container th {
    color: #353535;
    text-align: center;
    padding: 10px;
    border-bottom: .5px solid #eeeeee;
    font-size: 16px;
    font-family: "NaturaSansRegularBold", Helvetica, Arial, sans-serif !important; }
  .growth-plan-report-table-container td {
    text-align: center;
    color: #777777;
    padding: 10px;
    border-bottom: .5px solid #eeeeee; }

.growth-plan-report-table-hover tr:hover {
  background-color: #f9f9f9 !important; }

.growth-plan-report-table-background {
  background: #f9f9f9; }

.growth-plan-report-tr {
  margin-top: 5em;
  margin-bottom: 5em;
  border-bottom: 2px dashed #bbbbbb; }

.growth-plan-report-box-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 3em; }
  .growth-plan-report-box-container h1 {
    color: #849dad; }

.growth-plan-report-box {
  width: 270px;
  height: 100px;
  background: #849dad;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }
  .growth-plan-report-box h3 {
    padding-bottom: .5em;
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
    .growth-plan-report-box h3 span {
      font-family: "NaturaSansRegularBold", Helvetica, Arial, sans-serif !important;
      color: #ffffff; }
  .growth-plan-report-box h4 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
    .growth-plan-report-box h4 span {
      color: #ffffff; }

.growth-plan-report-table-container-second {
  width: 100%; }
  .growth-plan-report-table-container-second th {
    color: #353535;
    text-align: center;
    padding: 10px;
    border-bottom: .5px solid #eeeeee;
    font-size: 16px;
    font-family: "NaturaSansRegularBold", Helvetica, Arial, sans-serif !important; }
    .growth-plan-report-table-container-second th a {
      cursor: pointer; }
  .growth-plan-report-table-container-second td {
    border-top: .5px solid #eeeeee;
    border-bottom: .5px solid #eeeeee;
    text-align: center;
    color: #777777;
    padding: 10px; }
  .growth-plan-report-table-container-second .growth-plan-report-table-tr {
    display: none; }

.growth-plan-report-table-second-tr {
  height: 3.5em;
  border-bottom: .5px solid #eeeeee;
  border-top: .5px solid #eeeeee; }

.growth-plan-report-table-second-tr-custom {
  border-bottom: 0 !important; }

.growth-plan-report-table-second-td {
  background: #f9f9f9; }

.growth-plan-report-table-border {
  height: 10px; }

.growth-plan-report-table-no-border td {
  border: none; }

.growth-plan-report-table-padding {
  padding-bottom: 30px; }

.growth-plan-report-icon-container {
  display: flex;
  justify-content: center;
  align-items: center; }
  .growth-plan-report-icon-container span {
    left: 2em; }

.growth-plan-report-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 1.6em;
  flex-grow: 1; }

.extratos-titulo-lista-pedidos {
  font-family: "NaturaSansRegular", Helvetica, Arial, sans-serif !important; }

.product-kit-report-page-title {
  margin-bottom: 1em;
  margin-top: 1em;
  font-family: "NaturaSansRegularBold", Helvetica, Arial, sans-serif !important; }
  .product-kit-report-page-title span {
    font-family: "NaturaSansRegularBold", Helvetica, Arial, sans-serif !important; }

.product-kit-report-page-table-container {
  color: #777777;
  display: flex;
  justify-content: center;
  align-items: center; }
  .product-kit-report-page-table-container .react-bs-table {
    width: 85% !important; }

.product-kit-report-page-table-header th {
  text-align: center !important;
  border-bottom-width: 0 !important;
  border: 0 !important;
  white-space: nowrap;
  font-size: 16px;
  font-family: "NaturaSansRegularBold", Helvetica, Arial, sans-serif !important; }

.product-kit-report-page-table-header .table-bordered {
  border-left: 0 !important;
  border-top: 0 !important;
  border-right: 0 !important;
  border-bottom: 1px solid #777777 !important; }

.product-kit-report-page-table-header .table {
  margin-bottom: 0px !important; }

.product-kit-report-page-table-body tr {
  border-left: 0 !important;
  border-top: 1px solid #eeeeee !important;
  border-right: 0 !important;
  border-bottom: 1px solid #eeeeee !important;
}
  .product-kit-report-page-table-body tr td {
    text-align: center !important;
    border: 0 !important;
    cursor: pointer;
    transition: .3s; }

.product-kit-report-page-table-body tr:hover {
  background: #f9f9f9;
  transition: .3s;
}

.product-kit-report-page-table-body .table-bordered {
  border: 0 !important; }

.product-kit-report-page-table-colors {
  width: 20px;
  height: 20px;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  -ms-border-radius: 50px;
  -o-border-radius: 50px;
  background: #000;
  border: 1px solid red; }

.product-kit-detail-tr {
  margin-top: 5em;
  margin-bottom: 3em;
  border-bottom: 2px dashed #bbbbbb; }

.product-kit-detail-page-title {
  margin-bottom: 1em;
  margin-top: 1em;
  font-family: "NaturaSansRegularBold", Helvetica, Arial, sans-serif !important; }
  .product-kit-detail-page-title span {
    font-family: "NaturaSansRegularBold", Helvetica, Arial, sans-serif !important; }

.product-kit-detail-page-subtitle {
  margin-top: 1em;
  margin-bottom: .4em;
  font-family: "NaturaSansRegularBold", Helvetica, Arial, sans-serif !important; }
  .product-kit-detail-page-subtitle span {
    font-family: "NaturaSansRegularBold", Helvetica, Arial, sans-serif !important; }

.product-kit-detail-box-container {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start; }

.product-kit-detail-box-header-body {
  margin-left: 5em;
  width: 100%; }

.product-kit-detail-box-header {
  padding: 15px; }

.product-kit-detail-box-body {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  height: 16em;
  margin-left: 1.5em;
  margin-top: 1em; }

.product-kit-detail-box-ball-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: .5em; }
  .product-kit-detail-box-ball-container span {
    letter-spacing: 1px;
    font-size: 15px;
    color: #7080A0; }

.product-kit-detail-box-ball {
  width: 5px;
  height: 5px;
  background: #849DAD;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  -ms-border-radius: 50px;
  -o-border-radius: 50px;
  margin-right: 1em; }

  .leader-kit-report-page-table-container {
    color: #777777; }

  .leader-kit-report-page-button {
    background-color: rgb(112, 128, 160);
    color: white }

  .list-leader-kit-details {
      padding : 10px;
      list-style: none;
      margin: 10px;
  }

  .list-leader-kit-details > li > label{
     font-weight: bold;
     margin-right: 5px;
  }

  .list-leader-kit-details > li{
      margin-top: 2px;
  }

  .list-leader-kit-details > li:first-child {
      margin-top: 6px;
  }

.elegibility-report-title {
  color: #7181a0 !important;
  margin-bottom: 2em;
  margin-top: 1em;
  margin-left: .55em;
  font-family: "NaturaSansRegularBold", Helvetica, Arial, sans-serif !important;
  font-weight: 500; }
  .elegibility-report-title span {
    font-family: "NaturaSansRegularBold", Helvetica, Arial, sans-serif !important; }

.elegibility-report-container {
  margin: 1em 1em 3em 1em; }
  .elegibility-report-container h4 {
    color: #777777 !important; }

.elegibility-criteria-container {
  display: inline-block;
  margin-top: 20px; }

.elegibility-criteria-container-main {
  display: flex;
  justify-content: space-around; }

.elegibility-criteria-content {
  border: 5px solid #eeeeee;
  padding: 10px; }

.box-left {
  width: 40%; }

.box-right {
  width: 50%; }

.elegibility-report-table-container {
  width: 100%; }
  .elegibility-report-table-container th {
    color: #353535;
    text-align: center;
    padding: 10px;
    border-bottom: 2px solid #dddddd;
    font-size: 16px;
    font-family: "NaturaSansRegular", Helvetica, Arial, sans-serif; }
  .elegibility-report-table-container td {
    text-align: center;
    color: #777777;
    padding: 10px;
    border-bottom: .5px solid #eeeeee; }
  .elegibility-report-table-container td:first-child {
    background-color: #f9f9f9 !important;
    width: 13em !important; }

.elegibility-report-page-table-container .table {
  margin: 0 !important;
  border-top: 0 !important; }

.elegibility-report-page-table-header th {
  color: #353535;
  text-align: center !important;
  padding: 10px !important;
  font-size: 16px;
  font-family: "NaturaSansRegularBold", Helvetica, Arial, sans-serif !important;
  border-bottom: 1px solid #dddddd !important; }

.elegibility-report-page-table-body td {
  text-align: center !important;
  color: #777777;
  padding: 10px !important;
  border-bottom: .5px solid #eeeeee; }

.elegibility-report-page-table-body td:nth-child(2) {
  background-color: #f9f9f9 !important; }

.elegibility-report-page-table-body tr:hover {
  background: #f9f9f9;
  transition: .3s; }

.elegibility-report-table-hover tr:hover {
  background-color: #f9f9f9 !important; }

.elegibility-plan-report-tr {
  margin-top: 4em;
  margin-bottom: 3em;
  border-bottom: 2px dashed #bbbbbb; }

.leader-report-container {
  display: flex;
  justify-content: flex-start;
  padding: 20px 20px 20px 0px;
}

.payment-tracker-container {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 1em;
  padding-bottom: 1em;
  padding-top: 1em;
  margin-top: 1em;
  width: 100%;
  height: 150px;
}

.payment-tracker-container-with-data {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 1em;
  padding-bottom: 2em;
  padding-top: 1em;
  margin-top: 1em;
  width: 100%;
}

.payment-tracker {
  width: 90%;
  margin: auto;
}

.payment-tracker-horizontal {
  display:table;
  width:100%;
  margin:0 auto;
  background-color:#FFFFFF;
}
.payment-tracker-horizontal .payment-tracker-step {
  display:table-cell;
  position:relative;
  padding:24px;
}
.payment-tracker-horizontal .payment-tracker-step:first-child .payment-tracker-step-bar-left,
.payment-tracker-horizontal .payment-tracker-step:last-child .payment-tracker-step-bar-right {
  display:none;
}
.payment-tracker-horizontal .payment-tracker-step .payment-tracker-step-circle {
  width: 45px;
  height: 45px;
  margin: 0 auto;
  background-color: #999999;
  border-radius: 50%;
  text-align: center;
  line-height: 30px;
  font-size: 26px;
  font-weight: 600;
  padding-top: 7px;
  color: #FFFFFF;
}
.payment-tracker-horizontal.green .payment-tracker-step.active .payment-tracker-step-circle {
  background-color:#8bbe33;
}
.payment-tracker-horizontal .payment-tracker-step.active .payment-tracker-step-circle {
  background-color: rgb(33,150,243);
}
.payment-tracker-horizontal .payment-tracker-step.calendar .payment-tracker-step-circle * {
	display:none;
}
.payment-tracker-horizontal .payment-tracker-step.calendar .payment-tracker-step-circle {
	-moz-transform: scaleX(-1);
	-o-transform: scaleX(-1);
	-webkit-transform: scaleX(-1);
	transform: scaleX(-1);
}
.payment-tracker-horizontal .payment-tracker-step.calendar .payment-tracker-step-circle:before {
	font-family:'Material Icons';
	font-weight:100;
	content: "\e8df";
}
.payment-tracker-horizontal .payment-tracker-step.money .payment-tracker-step-circle * {
	display:none;
}
.payment-tracker-horizontal .payment-tracker-step.money .payment-tracker-step-circle {
	-moz-transform: scaleX(-1);
	-o-transform: scaleX(-1);
	-webkit-transform: scaleX(-1);
	transform: scaleX(-1);
}
.payment-tracker-horizontal .payment-tracker-step.money .payment-tracker-step-circle:before {
	font-family:'Material Icons';
	font-weight:100;
	content: "\e263";
}
.payment-tracker-horizontal .payment-tracker-step.invoice .payment-tracker-step-circle * {
	display:none;
}
.payment-tracker-horizontal .payment-tracker-step.invoice .payment-tracker-step-circle {
	-moz-transform: scaleX(-1);
	-o-transform: scaleX(-1);
	-webkit-transform: scaleX(-1);
	transform: scaleX(-1);
}
.payment-tracker-horizontal .payment-tracker-step.invoice .payment-tracker-step-circle:before {
	font-family:'Material Icons';
	font-weight:100;
	content: "\e85d";
}

.payment-tracker-horizontal .payment-tracker-step.description .payment-tracker-step-circle * {
	display:none;
}
.payment-tracker-horizontal .payment-tracker-step.description .payment-tracker-step-circle {
	-moz-transform: scaleX(-1);
	-o-transform: scaleX(-1);
	-webkit-transform: scaleX(-1);
	transform: scaleX(-1);
}
.payment-tracker-horizontal .payment-tracker-step.description .payment-tracker-step-circle:before {
	font-family:'Material Icons';
	font-weight:100;
	content: "\e873";
}

.payment-tracker-horizontal .payment-tracker-step .payment-tracker-step-title {
  margin-top:8px;
  font-size:14px;
  font-weight:600;
}
.payment-tracker-horizontal .payment-tracker-step .payment-tracker-step-title,
.payment-tracker-horizontal .payment-tracker-step .payment-tracker-step-optional {
  text-align: center;
  color:rgba(0,0,0,.26);
}
.payment-tracker-horizontal .payment-tracker-step.active .payment-tracker-step-title {
  font-weight: 600;
  color:rgba(0,0,0,.87);
}
.payment-tracker-horizontal .payment-tracker-step-title,
.payment-tracker-horizontal .payment-tracker-step .payment-tracker-step-optional {
  font-size:12px;
}
.payment-tracker-horizontal .payment-tracker-step.active .payment-tracker-step-optional {
  color:rgba(0,0,0,.54);
}
.payment-tracker-horizontal .payment-tracker-step .payment-tracker-step-bar-left,
.payment-tracker-horizontal .payment-tracker-step .payment-tracker-step-bar-right {
  position:absolute;
  top:48px;
  height:1px;
  border-top:1px solid #DDDDDD;
}
.payment-tracker-horizontal .payment-tracker-step .payment-tracker-step-bar-right {
  right:0;
  left:51%;
  margin-left:19px;
}
.payment-tracker-horizontal .payment-tracker-step .payment-tracker-step-bar-left {
  left:0;
  right:51%;
  margin-right:20px;
}

.payment-tracker .content-filter .display-data .list {
    list-style: none;
    padding-left: 0;
    margin-left: 0;
}

.payment-tracker-table-footer {
  padding: 0px;
}

.payment-tracker-first-table {
  width: 100%;
  padding: 30px;
  margin-bottom: 20px;
}

.payment-tracker-second-table {
  width: 100%;
  height: 360px;
  padding: 30px;
  margin-bottom: 20px;
}

.payment-tracker-third-table {
  width: 100%;
  height: 320px;
  padding: 30px;
  margin-bottom: 20px;
}

.payment-tracker-table {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 1em;
  padding-bottom: 1em;
  padding-top: 1em;
  margin-top: 1em;
}

.payment-tracker-total {
  text-align: center;
  background-color: #849dad;
  font-size: 15px;
}

.payment-tracker-header {
  margin-left: .55em;
  color: #7181a0;
  letter-spacing: 1px;
}

.leader-report-extract {
  width: 100%;
  margin-right: 0.7em;
}

.leader-report-extract .button-leader-filter{
  margin-top: 30px;
}

.leader-report-information {
  width: 35%; }
  .leader-report-information .list-information {
    margin-left: 0.7em; }
    .leader-report-information .list-information .item-list {
      display: flex;
      margin-bottom: 10px; }
    .leader-report-information .list-information .item-emblem {
      height: 0px; }
.account-report-paper {
  width: 100%;
  height: 500px;
  padding: 20px;
}

.consultant-report-paper {
  width: 100%;
  height: 200px;
  padding: 20px;
}

.consultant-report-body {
  color: #777777;
  padding: 1em 1em 0 2em;
  line-height: 2.5;
}

.display-errors{
  color: red;
}

.paper-header-errors {
  color: #7181a0;
  letter-spacing: 1px;
  margin-top: 30px;
}

.leader-report-container-errors {
  display: flex;
  justify-content: flex-start;
  padding-top: 10px;
}

.display-errors, ul {
  list-style: none;
  padding: 0px;
  margin: 0px;
}

.flat-button-weight {
  line-height: inherit !important;
  height: inherit !important;
}

/* Indicators */
.indicators-loading{
  margin: 3em 3em 1em 3em;
  text-align: center;
}
.indicators-loading p{
  margin-top: 1.5em;
}
.indicators-table-body-container-class tr:hover {
  background-color: #f9f9f9 !important;
  cursor: pointer; }

.indicators-table-container-class {
  margin-top: 1em; }
  .indicators-table-container-class .table {
    margin-bottom: 0; }
  .indicators-table-container-class .table-bordered {
    border: 0; }
  .indicators-table-container-class th {
    vertical-align: top !important;
    text-align: left !important;
    border-bottom-width: 0 !important;
    border: 0 !important;
    font-size: 16px; }
  .indicators-table-container-class tr {
    background-color: #ffffff !important;
    border-bottom: .5px solid #efefef !important; }
    .indicators-table-container-class tr td {
      text-align: left !important;
      border-bottom-width: 0 !important;
      border: 0 !important;
      color: rgba(0, 0, 0, 0.5); }
  .indicators-table-container-class .indicators-table-header-container-class {
    border-bottom: .5px solid #c7c7c7 !important;
    color: #777777; }

#fv-feat-fe{
  margin: 0 -20px !important;
}


.external-report-page-base-title-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 3em; }

.external-report-page-base-title-container h3 {
  margin: 0;
  display: flex;}

/*# sourceMappingURL=styles.css.map */
